/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Globe from '../images/illustrations/clearlink-locations-map-blue-dots.svg'

import TwoColumn from '../components/GridLayouts/TwoColumn'
import UsingMobilePhone from '../images/devices/using-mobile-phone-black-and-white.jpg'
import UsingMobilePhoneMobile from '../images/devices/using-mobile-phone-black-and-white-mobile.jpg'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Billboard,
  Image,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'
import NarrowBanner from '../components/NarrowBanner'

import createImage from '../utils/createImage'

const Home = () => {
  const GlobeImage = createImage([Globe], 579, 365)
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Media & Advertising | Clearlink',
          description:
            'Through award-winning performance marketing, proprietary technology, and expertly written content, we help 280 million people a year make better decisions.',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/what-we-do',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Media & Advertising | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={UsingMobilePhone}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={UsingMobilePhoneMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <>
              <Typography variant="h1">
                We build brands that guide people to make the best decisions for
                themselves<span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                A tech-enabled, global media company
              </Typography>
              <LinkButton to={`/careers`} variant="hero" color="secondary">
                Join Our Team
              </LinkButton>
            </>
          }
        />

        <VariableContent backgroundColor="primary">
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography
              variant="h2"
              className="narrow center-desktop center"
              style={{ marginBottom: '32px' }}
            >
              We help you find, buy, and use products and services that improve
              your life
              <span className="white-punctuation-large">.</span>
            </Typography>
            <Typography
              variant="body"
              className="narrow center-desktop center"
              style={{ marginBottom: '32px' }}
            >
              We create better experiences to guide consumers on a path of
              self-selection.
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '24px' }}
          >
            <div
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              {isMobile ? (
                <StaticImage
                  src="../images/illustrations/our-properties-white-mobile.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : (
                <StaticImage
                  src="../images/illustrations/our-properties-white.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </div>
          </div>
          <LinkButton
            to={`/our-properties`}
            color="dark"
            className="margin-x-auto"
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            More About Our Properties
          </LinkButton>
        </VariableContent>

        <VariableContent alignMainContent="center">
          <Stack spacing="l">
            <TwoColumn
              leftColumnAlign="right"
              leftWidth={1}
              rightWidth={3}
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h2">75M+</Typography>
                  <Typography>Qualified leads for partners per year</Typography>
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h4">Performance Marketing</Typography>
                  <Typography>
                    We connect over 75 million high-value customers with the
                    world’s leading brands. Across verticals and channels, we
                    provide advertisers new and engaged audiences
                  </Typography>
                </div>
              }
            />
            <TwoColumn
              leftColumnAlign="right"
              leftWidth={1}
              rightWidth={3}
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h2">1B+</Typography>
                  <Typography>Interact with our content each year</Typography>
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h4">Expert Content</Typography>
                  <Typography>
                    Our experts create best-in-class content to help consumers
                    make informed decisions. We build loyal, engaged audiences
                    through video, paid media, email, websites, display, social
                    media, and more.
                  </Typography>
                </div>
              }
            />
            <TwoColumn
              leftColumnAlign="right"
              leftWidth={1}
              rightWidth={3}
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h2">1.5B+</Typography>
                  <Typography>GMV for brand partners each year</Typography>
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h4">Proprietary Technology</Typography>
                  <Typography>
                    Our proprietary tools and technology enable users to easily
                    compare products and services. Our in-house technology
                    enables us to quickly scale our content and reach, so we can
                    help as many people as possible.
                  </Typography>
                </div>
              }
            />{' '}
            <TwoColumn
              leftColumnAlign="right"
              leftWidth={1}
              rightWidth={3}
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h2">UX</Typography>
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h4">Elevated User Experience</Typography>
                  <Typography>
                    We put users first and create better online experiences that
                    empower people. We compile proprietary, in-house data based
                    on real customer experiences to help people make decisions
                    to improve their everyday lives.
                  </Typography>
                </div>
              }
            />{' '}
            <TwoColumn
              leftColumnAlign="right"
              leftWidth={1}
              rightWidth={3}
              leftContent={
                <div
                  data-sal="slide-right"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h2">LTV</Typography>
                </div>
              }
              rightContent={
                <div
                  data-sal="slide-left"
                  data-sal-delay="600"
                  data-sal-duration="500"
                  data-sal-easing="ease"
                >
                  <Typography variant="h4">Valuable Partnerships</Typography>
                  <Typography>
                    Our user-first philosophy means we connect people to the
                    right services and products for their needs, leading to
                    higher LTV customers. Then we leverage our vast user base to
                    help drive strategic priorities.
                  </Typography>
                </div>
              }
            />
          </Stack>
        </VariableContent>

        <SplitContent
          backgroundColor="dark"
          className="reverse-desktop"
          image={
            <div
              data-sal="slide-left"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Image data={GlobeImage} />
            </div>
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Where We Work<span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography>
                An international workforce and brand partners with a worldwide
                network of consumers help us to reach all across the globe.
              </Typography>
            </div>
          }
        />

        <NarrowBanner
          backgroundColor="#1990FF"
          heading={
            // Had to remove animation, 'cause it was making the element disappear
            // for some strange reason
            <Typography variant="h2">
              Interested in joining our team?
            </Typography>
          }
          button={
            <LinkButton
              to="/careers"
              color="dark"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Explore Careers
            </LinkButton>
          }
        />
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}
export default Home
